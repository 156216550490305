<template>
  <div class="inner-pages hd-white">
     <img :src="require('../../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />
     <h3 data-v-67fec170="" data-v-4f8ed385="" class="pase text-center" style="font-weight: 700; font-size: 45px;"> Blog </h3>
    <section style="background: white;" class="blog blog-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <div class="row">
              <BlogsWithPagination/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import BlogsWithPagination from "./blogs-with-pagination.vue"
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "bloglist",
  components: {
    BlogsWithPagination,
  },
  computed: {
    getTopFiveProperties(){
      return this.properties.properties ? this.properties.properties.slice(0,5) : []
    },
    ...mapGetters({
      popularTags: "getPopularTags",
      categories: "getCategories",
      properties: "getProperties"
    }),
  },
  filters:{
    convertDate: value => {
      return moment(value).format(
        "MMM DD, YYYY"
      );
    }
  },
  async mounted() {
    if (!this.categories) this.$store.dispatch("categories");
    if (!this.popularTags) {
      this.$store.dispatch("popularTags");
    }
    if (_.isEmpty(this.properties)) {
      await this.$store.dispatch("getAllProperties", {
        currentPage: 1,
        perPage: 5,
        sortItem: "id",
        sortOrder: "desc",
        search: "",
      });
    }
  },
};
</script>

<style scoped src="../../assets/css/fontawesome-all.css"></style>
<style scoped src="../../assets/css/font-awesome.min.css"></style>
<style scoped src="../../assets/css/styles.css"></style>

<style scoped>

.green-button {
  border-color: #548f4d !important;
  background: linear-gradient(-47deg, #548f4d 0%, #548f4d 100%) !important;
}

div >>> .page-item.active .page-link {
  background-color: #55904e;
  border-color: #55904e;
}
div >>> .page-link {
  color: #55904e;
}
</style>